@media (min-width: 768px) {
  .navbar-nav>li>a {
    /* (80px - line-height of 27px) / 2 = 26.5px */
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 27px;
  }
}

body {
  padding-top: 50px !important;
}

.icon-back {
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px;
}

.icon-coin {
  max-width: 18px;
  max-height: 18px;
  margin-right: 5px;
}

.icon-reload {
  max-width: 14px;
  max-height: 14px;
  margin-left: 10px;
}